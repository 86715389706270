@font-face {
	font-family: 'Inter';
	src: url('/fonts/inter/inter.ttf') format('truetype');
	font-weight: 100 900;
	font-display: swap;
}
@font-face {
	font-family: 'Inter';
	src: url('/fonts/inter/inter-italic.ttf') format('truetype');
	font-style: italic;
	font-weight: 100 900;
	font-display: swap;
}

/* OPENLANE Poppins Font */

@font-face {
	font-family: 'Poppins';
	src:
		url('/fonts/poppins/pr.woff2') format('woff2'),
		url('/fonts/poppins/pr.ttf') format('ttf'),
		url('/fonts/poppins/pr.woff') format('woff'),
		url('/fonts/poppins/pr.otf') format('otf');
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src:
		url('/fonts/poppins/pb.woff2') format('woff2'),
		url('/fonts/poppins/pb.ttf') format('ttf'),
		url('/fonts/poppins/pb.woff') format('woff'),
		url('/fonts/poppins/pb.otf') format('otf');
	font-weight: bold;
	font-display: swap;
}
